import { useDispatch } from "react-redux";
import { analyticsAction } from "../../../externals";
import { useCallback } from "react";

export type UseGbliSignInAnalyticsReturn = {
  sendGbliSignInPageViewEvent: () => void;
  sendGbliSignInEvent: (userEmail: string) => void;
};

export enum GbliAnalyticsEvent {
  PAGE_VIEW = "GBLI Sign In Page",
  SIGN_IN = "GBLI Sign In Form"
}

const useGbliSignInAnalytics = (): UseGbliSignInAnalyticsReturn => {
  const dispatch = useDispatch();
  const sendGbliSignInPageViewEvent = useCallback(() => {
    dispatch(
      analyticsAction({
        actionName: GbliAnalyticsEvent.PAGE_VIEW,
        type: "View"
      })
    );
  }, [dispatch]);
  const sendGbliSignInEvent = useCallback(
    (userEmail: string) => {
      dispatch(
        analyticsAction({
          actionName: GbliAnalyticsEvent.SIGN_IN,
          type: "Submit",
          properties: {
            userEmail
          }
        })
      );
    },
    [dispatch]
  );
  return { sendGbliSignInEvent, sendGbliSignInPageViewEvent };
};

export default useGbliSignInAnalytics;
