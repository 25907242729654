import React, { useMemo } from "react";
import { AuthFormData } from "@pie/components/dist/auth/AuthProvider/AuthContext";
import { AuthFormStatusType, useAuth } from "@pie/components";
import { SetNewPassword as DefaultSetNewPassword } from "@pie/components/dist/auth/SetNewPassword/SetNewPassword";
import { ForgotPassword as DefaultForgotPassword } from "@pie/components/dist/auth/ForgotPassword/ForgotPassword";
import { ResetPassword as DefaultResetPassword } from "@pie/components/dist/auth/ResetPassword/ResetPassword";
import { ForgotPasswordSent as DefaultForgotPasswordSent } from "@pie/components/dist/auth/ForgotPasswordSent/ForgotPasswordSent";
import { SignIn as DefaultSignIn } from "@pie/components/dist/auth/SignIn/SignIn";
import { Snackbar } from "@pie/components";

export interface AuthFormComponentProps {
  data?: AuthFormData;
}
type AuthFormComponent = (props: AuthFormComponentProps) => JSX.Element | null;
type AuthFormProps = {
  SetNewPassword?: AuthFormComponent;
  ForgotPassword?: AuthFormComponent;
  ResetPassword?: AuthFormComponent;
  ForgotPasswordSent?: AuthFormComponent;
  SignIn?: AuthFormComponent;
};

/**
 * This is a copy of the AuthForm from Core, but one that is customizable.
 * Re-implementing AuthForm here was easier than fixing all the issues in Core.
 */
export const AuthForm: React.FunctionComponent<AuthFormProps> = ({
  SetNewPassword = DefaultSetNewPassword,
  ForgotPassword = DefaultForgotPassword,
  ResetPassword = DefaultResetPassword,
  ForgotPasswordSent = DefaultForgotPasswordSent,
  SignIn = DefaultSignIn
}: AuthFormProps) => {
  const { authFormData, authErrors, clearAuthErrors } = useAuth();
  const FormComponent = useMemo(() => {
    const map: Record<AuthFormStatusType, AuthFormComponent> = {
      [AuthFormStatusType.SET_NEW_PASSWORD]: SetNewPassword,
      [AuthFormStatusType.FORGOT_PASSWORD]: ForgotPassword,
      [AuthFormStatusType.RESET_PASSWORD]: ResetPassword,
      [AuthFormStatusType.FORGOT_EMAIL_SENT]: ForgotPasswordSent,
      [AuthFormStatusType.LOGIN]: SignIn
    };
    return map[authFormData.formType] ?? SignIn;
  }, [
    authFormData.formType,
    SetNewPassword,
    ForgotPassword,
    ResetPassword,
    ForgotPasswordSent,
    SignIn
  ]);

  const handleChange = (): void => {
    if (authErrors.length > 0) {
      clearAuthErrors();
    }
  };

  return (
    <div onChange={handleChange}>
      {authErrors.length > 0 &&
        authErrors.map((err, i) => (
          <Snackbar
            key={i}
            id="snackbar-auth-error"
            displayStyle="error"
            open={true}
            message={err.message}
          />
        ))}
      <FormComponent data={authFormData} />
    </div>
  );
};
