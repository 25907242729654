import { Theme, makeStyles } from "@material-ui/core";

export const signInStyles = makeStyles((theme: Theme) => ({
  button: {
    margin: theme.spacing(3.5, 0, 1.5, 0)
  },
  form: {
    textAlign: "center"
  },
  root: {
    height: "100vh",
    width: theme.spacing(52),
    margin: "auto"
  },
  card: {
    minWidth: theme.spacing(87.5), // 700px
    padding: theme.spacing(3)
  },
  image: { height: "auto", width: "220px", marginBottom: theme.spacing(2.5) },
  authForm: {
    margin: "auto",
    width: "370px"
  },
  disclaimer: {
    marginTop: theme.spacing(4)
  },
  gbliLogoContainer: {
    marginBottom: "32px"
  },
  pennAmericaGroupLogo: {
    borderRight: "2px solid #ccc",
    padding: "20px 8px",
    width: "300px"
  },
  pieLogoContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "12px"
  },
  pieLogoHeader: {
    fontWeight: "bold"
  },
  pieLogo: {
    width: "300px"
  },
  requestAccount: {
    display: "flex",
    justifyContent: "center",
    marginTop: "28px"
  }
}));
