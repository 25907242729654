import { AuthFormComponentProps } from "@pie/components/dist/auth/AuthForm/AuthForm";
import React from "react";
import {
  AuthUiInteractionType,
  LoadingButton,
  Password,
  TextInputInfoLabel,
  useAuth
} from "@pie/components";
import { signInStyles } from "./styles";
import { ForgotPasswordLink } from "@pie/components/dist/auth/ForgotPasswordLink/ForgotPasswordLink";
import useGbliSignInAnalytics from "./useGbliSignInAnalytics";

export const buttonText = "Sign In";
export const emailInputLabel = "Email Address";

export type SignInFormData = { email?: string };

export const SignInForm: React.FunctionComponent<AuthFormComponentProps> = ({
  data
}) => {
  const { sendGbliSignInEvent } = useGbliSignInAnalytics();
  const payload = data?.payload as SignInFormData;
  const { login, checkIdP, loading } = useAuth();
  const [email, setEmail] = React.useState<string>(payload?.email || "");
  const [password, setPassword] = React.useState<string>("");

  const normalizeEmail = (value: string): string =>
    value ? value.replace(" ", "") : value;
  const handleBlur = (): void => {
    checkIdP(email);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.currentTarget;
    const normalizedValue = normalizeEmail(value);
    setEmail(normalizedValue);
  };
  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const { value } = e.currentTarget;
    setPassword(value);
  };

  const handleSubmit = (e: React.FormEvent): void => {
    e.preventDefault();
    if (email.length > 1 && password.length > 1) {
      sendGbliSignInEvent(email);
      login({ email, password });
    }
  };

  const { button, form } = signInStyles();

  return (
    <form onSubmit={handleSubmit} className={form}>
      <TextInputInfoLabel
        required
        id="emailAddress"
        type="text"
        label={emailInputLabel}
        name="emailAddress"
        value={email}
        onChange={handleEmailChange}
        onBlur={handleBlur}
        normalizer={normalizeEmail}
      />
      <Password
        id="password"
        label="Pie Password"
        name="password"
        value={password}
        onChange={handlePasswordChange}
      />
      <LoadingButton
        loading={loading}
        className={button}
        type="submit"
        variant="contained"
        color="primary"
        disabled={!email || !password}
      >
        {buttonText}
      </LoadingButton>
      <ForgotPasswordLink
        authEventName={AuthUiInteractionType.SIGN_IN_FORGOT_PASSWORD_LINK_CLICK}
      />
    </form>
  );
};
