import React, { useEffect } from "react";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import GbliSignIn from "./GbliSignIn";
import { PieSignIn } from "./PieSignIn";

export const isFromGBLI = (referrer: string): boolean => {
  try {
    // Provide a query param alternative, to easier test this functionality
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("gbli")) return true;

    // Referer (sic) header is the expected method
    const url = new URL(referrer);
    return /^(www\.)?(.*\.)*penn-link.com$/.test(url.hostname);
  } catch {
    return false;
  }
};

export const SignIn: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    if (location.search.includes("redirect=1")) {
      const search = queryString.parse(location.search);
      delete search.redirect;
      const newParams = queryString.stringify(search);

      let newLocation = location.pathname;
      if (newParams.length !== 0) {
        newLocation += `?${newParams}`;
      }
      history.push(newLocation);
    }
  }, [history, location.pathname, location.search]);
  const SignInComponent = isFromGBLI(document.referrer)
    ? GbliSignIn
    : PieSignIn;

  return <SignInComponent />;
};
