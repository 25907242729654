import { signInStyles } from "./styles";
import { Grid, Link, Typography } from "@material-ui/core";
import { Card } from "@pie/components";
import { Icon as IconAtom, LogoOrangeNameRight } from "@pie/icon-atom";
import { AuthForm } from "../../../components/AuthForm/AuthForm";
import { SignInForm } from "./SignInForm";
import React, { useEffect } from "react";
import useGbliSignInAnalytics from "./useGbliSignInAnalytics";

export const GbliSignIn = (): JSX.Element => {
  const { sendGbliSignInPageViewEvent } = useGbliSignInAnalytics();
  useEffect(() => {
    sendGbliSignInPageViewEvent();
  }, [sendGbliSignInPageViewEvent]);

  const {
    authForm,
    card,
    disclaimer,
    gbliLogoContainer,
    pennAmericaGroupLogo,
    pieLogoContainer,
    pieLogoHeader,
    pieLogo,
    requestAccount,
    root
  } = signInStyles();

  // TODO: AuthForm should take a function to dispatch an analytics call to
  // update role.
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={root}
    >
      <Grid item>
        <Card className={card} displayStyle="tertiary">
          <Grid container justifyContent="center" className={gbliLogoContainer}>
            <Grid item>
              <img
                alt="Penn America Group Logo"
                className={pennAmericaGroupLogo}
                data-testid="pennAmericaLogo"
                src="/penn-america-group.png"
              />
            </Grid>
            <Grid item className={pieLogoContainer}>
              <Typography className={pieLogoHeader}>
                In Partnership With
              </Typography>
              <IconAtom className={pieLogo} source={LogoOrangeNameRight} />
            </Grid>
          </Grid>
          <div className={authForm}>
            <AuthForm SignIn={SignInForm} />
          </div>
          <div className={requestAccount}>
            <Typography>
              New to Pie?{" "}
              <Link
                href="https://www.pieinsurance.com/penn-america-group"
                rel="noopener noreferrer"
                target="_blank"
                underline="always"
              >
                Request an account
              </Link>
            </Typography>
          </div>
          <div className={disclaimer}>
            Policies are sold and administered by Pie Insurance Services, Inc.,
            a licensed insurance producer (licenses available at{" "}
            <Link
              href="https://pieinsurance.com/legal/"
              rel="noopener noreferrer"
              target="_blank"
              underline="always"
            >
              pieinsurance.com/legal/
            </Link>
            ) and underwritten by affiliated and unaffiliated insurers.
            Information provided by an applicant or insured is subject to our
            Privacy Policy (
            <Link
              href="https://pieinsurance.com/privacy/"
              rel="noopener noreferrer"
              target="_blank"
              underline="always"
            >
              pieinsurance.com/privacy/
            </Link>
            ). Not available in all states and situations. Coverage subject to
            policy terms and conditions.
          </div>
        </Card>
      </Grid>
    </Grid>
  );
};
